import React from "react";

const RouteIcon = () => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 485 485"
    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root css-vubbuv"
    // MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv : For Prod
    focusable={false}
    aria-hidden
  >
    <g>
      <path
        d="M425.661,339.076H259.036c-15.418,0-27.962-12.544-27.962-27.962s12.544-27.962,27.962-27.962h137.167
		c6.192,17.459,22.865,30,42.42,30c24.813,0,45-20.187,45-45c0-19.555-12.541-36.228-30-42.42V0h-152.96v131.256h122.96v94.476
		c-12.764,4.527-22.893,14.656-27.42,27.42H259.036c-31.96,0-57.962,26.001-57.962,57.962s26.002,57.962,57.962,57.962h166.625
		c15.418,0,27.962,12.543,27.962,27.962S441.079,425,425.661,425H146.096c-6.192-17.459-22.865-30-42.42-30
		c-24.813,0-45.001,20.187-45.001,45s20.188,45,45.001,45c19.555,0,36.228-12.542,42.42-30h279.565
		c31.96,0,57.962-26.001,57.962-57.962S457.621,339.076,425.661,339.076z M330.663,101.256V30h92.96v71.256H330.663z
		 M438.623,253.152c8.271,0,15,6.729,15,15s-6.729,15-15,15s-15-6.729-15-15S430.352,253.152,438.623,253.152z M103.676,455
		c-8.271,0-15.001-6.729-15.001-15s6.729-15,15.001-15s15,6.729,15,15S111.947,455,103.676,455z"
      />
      <path
        d="M138.676,189.498c0-19.299-15.701-35-35.001-35c-19.299,0-35,15.701-35,35s15.701,35,35,35
		C122.975,224.498,138.676,208.797,138.676,189.498z"
      />
      <path
        d="M183.941,252.86c14.414-18.226,22.032-40.137,22.032-63.363c0-56.407-45.891-102.298-102.299-102.298
		C47.268,87.2,1.377,133.09,1.377,189.498c0,23.227,7.619,45.137,22.059,63.396l80.239,100.883L183.941,252.86z M31.377,189.498
		c0-39.865,32.433-72.298,72.298-72.298s72.299,32.433,72.299,72.298c0,16.403-5.382,31.879-15.537,44.721l-56.762,71.364
		L46.94,234.251C36.759,221.377,31.377,205.901,31.377,189.498z"
      />
    </g>
  </svg>
);

export default RouteIcon;
