import { useMemo } from "react";

export default (t) => useMemo(() => ({
  color: {
    name: t("attributeColor"),
    type: "string",
  },
  speedLimit: {
    name: t("attributeSpeedLimit"),
    type: "number",
    subtype: "speed",
  },
  polylineDistance: {
    name: t("attributePolylineDistance"),
    type: "number",
    subtype: "distance",
  },
}), [t]);
