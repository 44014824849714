export default {
  sidebarWidth: "28%",
  sidebarWidthTablet: "52px",
  drawerWidthDesktop: "400px",
  drawerWidthTablet: "320px",
  drawerHeightPhone: "250px",
  filterFormWidth: "160px",
  eventsDrawerWidth: "320px",
  bottomBarHeight: 56,
  popupMapOffset: 300,
  popupMaxWidth: 360,
  popupImageHeight: 144,
};
