export default [
  "default",
  "animal",
  "bicycle",
  "boat",
  "bus",
  "boomTruck",
  "car",
  "crane",
  "compactor",
  "dumbTruck",
  "helicopter",
  "hookLifter",
  "lowBedTrailer",
  "motorcycle",
  "offroad",
  "person",
  "pickup",
  "plane",
  "ship",
  "sweeper",
  "skidLoader",
  "tractor",
  "train",
  "tram",
  "trolleybus",
  "truck",
  "van",
  "scooter",
  "waterTruck",
  "wreckerTruck",
];
